import { Paper, Popper } from '@mui/material';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import useOutSideClick from '../../../shared/hooks/UseOutsideClick';
import instance from '../../../shared/interceptor';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import UserProfileTooltip from '../../../shared/shared-comp/UserProfileTooltip';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import {
  debounce,
  formatDate,
  getFullname,
  getInitials,
  globalError,
  setLoader,
} from '../../../shared/util/util';

function CreditHistory({ setValue }) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const [page, setPage] = useState(1);
  const [allocation, setAllocation] = useState(null);
  const [query, setQuery] = useState('');
  const [random, setRandom] = useState(1);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    getCreditHistory();
  }, [query, page]);

  const getCreditHistory = async () => {
    try {
      setShowLoading(true);
      let url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/credit_allocations?q=${query}&page=${page}`;
      let res = await instance.get(url);
      setAllocation(res);
      if (!res?.['credit_allocations']?.length) {
        setShowEmptyState(true);
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setShowLoading(false);
    }
  };

  const handlePaginationChange = (e, val) => {
    setPage(val);
  };

  const searchSpace = debounce((e) => {
    setQuery(e?.target?.value);
    setPage(1);
  });

  const showUserTooltip = (e, row) => {
    e?.stopPropagation();
    setPopperAnchorEl({ [row?.id]: e.currentTarget });
  };

  const userImageRef = useOutSideClick((e) => {
    setPopperAnchorEl(null);
  });

  //===============================

  return (
    <div data-testid="credit-history-section">
      <div className="allocation-header">
        <h4>Credit allocation/deallocation history</h4>
        <div className="right-filter">
          <div className="form-group">
            <FormControl variant="filled">
              {/* @ts-ignore */}
              <FilledInput
                inputProps={{ 'data-testid': 'spaceSearch' }}
                type="text"
                className="xs-input search-input-wrap"
                placeholder="Search spaces"
                startAdornment={
                  <InputAdornment position="start">
                    <em className="be-search"></em>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" className="search-icon-wrap">
                    {query && (
                      <img
                        src={images.iconCrossCircle}
                        alt=""
                        onClick={() => {
                          setQuery('');
                          setRandom((prev) => prev + 1);
                        }}
                      />
                    )}
                  </InputAdornment>
                }
                key={random}
                onChange={searchSpace}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="table-wrapper table-responsive history-list-table">
        {showLoading && !query && <LoaderComp maxHeight="30rem" />}
        {showLoading && query && (
          <div className="searching-loader">
            <div className="dot-flashing"></div>
          </div>
        )}
        {!showLoading && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="credit-history-table">
              <TableHead>
                <TableRow>
                  <TableCell>DATE</TableCell>
                  <TableCell align="right">ALLOCATED/DEALLOCATED</TableCell>
                  <TableCell>SPACE</TableCell>
                  <TableCell className="w-300">Executed by</TableCell>
                  <TableCell align="right">Credit balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!allocation?.credit_allocations?.length && (
                  <TableRow>
                    {!query && showEmptyState && (
                      <TableCell colSpan={5}>
                        <div
                          className="no-data-wrapper no-allocation-yet"
                          aria-label="empty-state"
                        >
                          <em className="thumb">
                            <img
                              style={{ borderRadius: 0 }}
                              src={images.emptyStateIllustration}
                              alt="Spaces Flag"
                            />
                          </em>
                          <h4 aria-label="no-space">No data yet</h4>
                          <p>
                            You have not allocated credits to your spaces yet.
                          </p>
                          <Button
                            variant="contained"
                            className="xs-btn purple-btn btn-new-board"
                            onClick={() => setValue('2')}
                          >
                            Allocate Credits
                          </Button>
                        </div>
                      </TableCell>
                    )}
                    {query && (
                      <TableCell colSpan={5}>
                        <div className="card manage-card no-search-results">
                          <em className="icon sm">
                            <img src={images.emptyBox} alt="" />
                          </em>
                          <h6>No results found</h6>
                          <p>{query} Space does not exist</p>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                )}
                {allocation?.credit_allocations?.map((row) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{formatDate(row?.created_at)}</TableCell>
                    <TableCell align="right">
                      <span
                        className={
                          row?.allocation_type == 'deallocated'
                            ? 'minus'
                            : 'plus'
                        }
                      >
                        {row?.allocation_type == 'deallocated'
                          ? `-${row?.credit}`
                          : `+${row?.credit}`}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className="icon be-multiple-users"
                        style={{ backgroundColor: row?.space_color }}
                      ></span>{' '}
                      {row?.space_name}
                    </TableCell>
                    <TableCell>
                      <div className="d-flex align-items-center">
                        <span
                          className="img initials"
                          style={{
                            backgroundColor: `${row?.actor?.profile_picture?.profile_picture_url ? '' : '#B388FF'}`,
                          }}
                        >
                          {row?.actor?.profile_picture?.profile_picture_url ? (
                            <img
                              src={
                                row?.actor?.profile_picture?.profile_picture_url
                              }
                              alt=""
                            />
                          ) : (
                            (getInitials(
                              getFullname(
                                row?.actor?.first_name,
                                row?.actor?.last_name
                              )
                            ) ?? 'S')
                          )}
                        </span>{' '}
                        {getFullname(
                          row?.actor?.first_name,
                          row?.actor?.last_name
                        ) ?? 'System'}
                      </div>
                    </TableCell>
                    <Popper
                      style={{ zIndex: 99 }}
                      disablePortal={true}
                      placement="left-end"
                      open={Boolean(popperAnchorEl?.[row?.id])}
                      anchorEl={popperAnchorEl?.[row?.id]}
                    >
                      <UserProfileTooltip {...{ user: row?.actor }} />
                    </Popper>
                    <TableCell align="right">{row?.credit_balance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="custom-pagination-wrapper justify-content-between">
        {allocation?.total_count > 0 && (
          <p>
            Showing {allocation?.credit_allocations?.length} out of{' '}
            {allocation?.total_count} results
          </p>
        )}
        {allocation?.total_pages > 1 && (
          <Pagination
            count={allocation?.total_pages}
            color="secondary"
            onChange={handlePaginationChange}
          />
        )}
      </div>
    </div>
  );
}

export default CreditHistory;
