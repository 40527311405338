import Tooltip from '@mui/material/Tooltip';
import { memo, startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import AddMemberToBmeetDialog from '../../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import ExitConfirmDialog from '../../../components/dialog/playground-dialog/ExitConfirmDialog';
import { usePrompt } from '../../../shared/hooks/usePrompt';
import instance from '../../../shared/interceptor';
import {
  clearBuildCardCache,
  createExternalAppSession,
  getCurrentSpaceDetail,
} from '../../../shared/services/common.service';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import {
  currentSpaceState,
  entBuildcardState,
  setBuildcardId,
  setEntBuildcardId,
} from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import {
  companyState,
  leavingUrlState,
  permissionState,
  setLeavingUrl,
  userState,
} from '../../../shared/store/slices/userSlice';
import { PROJECTS, SPACE_LIST, SPACES } from '../../../shared/util/constant';
import {
  globalError,
  isAllowedTo,
  setLoader,
  transNavigation,
} from '../../../shared/util/util';
import './playgroundWelcome.scss';

export default function PlaygroundWelcome() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const userData = useSelector(userState);
  const entBuildcardSel = useSelector(entBuildcardState);
  let entId = '';
  const leavingUrlSel = useSelector(leavingUrlState);
  const { state } = useLocation();
  const permissions = useSelector(permissionState);

  const [frame, setFrame] = useState(false);
  const [callMyTeam, setCallMyTeam] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [leavingUrlSt, setLeavingUrlSt] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [isFromPlayground, setIsFromPlayground] = useState(state);

  useEffect(() => {
    setIsFromPlayground(state);
    if (!currentSpaceSel) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    }
  }, []);

  useEffect(() => {
    const handler = (
      ev: MessageEvent<{ type: string; buildcard_id: string }>
    ) => {
      if (typeof ev?.data !== 'object') return;
      if (!ev?.data?.type) return;
      if (!['buildcard-save', 'update-buildcard'].includes(ev?.data?.type)) {
        clearBuildCardCache(params?.bc_id, params?.id, compSel?.id, dispatch);
        return;
      }
      if (!ev?.data?.buildcard_id) return;
      if (ev?.data?.buildcard_id && ev?.data?.type == 'buildcard-save') {
        console.log('event data', ev?.data);
        dispatch(setBuildcardId(ev.data.buildcard_id));
        saveBuildcardId(ev.data.buildcard_id);
      } else {
        setFrame(false);
        console.log(
          'navigating',
          ev?.data,
          entBuildcardSel,
          'entBuildcardSel',
          'entId',
          entId
        );
        // don't know why entBuildcardSel is undefined
        // getting entBuildcardSel when used as dependency but even after using memo
        // iframe rerenders and close when this variable is changed
        dispatch(setSuccessMsg('Buildcard saved successfully.'));
        navigate(
          `/company/spaces/${currentSpaceSel?.id}/playground/${entBuildcardSel ?? entId}`,
          { replace: true }
        );
        console.log(
          `/company/spaces/${currentSpaceSel?.id}/playground/${entBuildcardSel ?? entId}`
        );
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
      setFrame(false);
      window.onbeforeunload = null;
    };
  }, [currentSpaceSel?.id]);

  useEffect(() => {
    setLeavingUrlSt(leavingUrlSel);
  }, [leavingUrlSel]);

  useEffect(() => {
    if (iframeLoading) {
      createExternalAppSession(params, compSel, 'studio', dispatch);
    }
  }, [iframeLoading]);

  const [showDialog, setShowDialog] = usePrompt(
    'Ensure all your changes are saved before exiting.',
    !!frame,
    showPrompt
  );

  const discardAndNavigate = () => {
    setShowDialog(false);
    setShowPrompt(false);
    setFrame(false);
    navigate(leavingUrlSt, { replace: true });
    window.onbeforeunload = null;
  };

  const saveBuildcardId = async (buildcardId) => {
    try {
      setLoader(dispatch, true);
      const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', currentSpaceSel?.id)}/build_cards`;
      const payload = {
        build_card: {
          studio_build_card_pid: buildcardId,
          space_id: currentSpaceSel?.id,
        },
      };
      let buildRes = await instance.post(url, payload);
      if (buildRes?.['build_card']) {
        dispatch(setEntBuildcardId(buildRes?.['build_card']['id']));
        entId = buildRes?.['build_card']['id'];
        // navigate(`/company/spaces/${currentSpaceSel?.id}/playground/${buildRes?.['build_card']['id']}`);
      }
    } catch (e) {
      let err = e?.['studio_build_card_pid']?.[0] ?? e;
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const callMyTeamDialogClose = () => {
    setCallMyTeam(false);
  };

  const isOwnerOrMemberSpace = () => {
    let member = currentSpaceSel?.members.some(
      (mem) => mem?.id == userData?.id
    );
    return (
      member ||
      userData?.id == currentSpaceSel?.owner?.id ||
      userData?.role?.name == 'CIO'
    );
  };

  const getWbUrl = async () => {
    try {
      if (!isOwnerOrMemberSpace()) {
        return globalError(dispatch, {
          message: 'You are not the owner/member of the space',
        });
      }
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${currentSpaceSel?.id}/whiteboards`;
      let res = await instance.post(url, null);
      navigate(
        `/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${res?.['whiteboard']?.id}`
      );
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleIframLoad = () => {
    setIframeLoading(false);
  };

  //================================================================
  return (
    <>
      {frame && (
        <div className="playground-page iframe-page-container">
          <div className="page-container top-space pb-0 sm-x-pad border-bottom">
            <div className="main-page-header">
              <div className="left-page-header">
                <div className="breadcrumb-wrap">
                  <div className="page-breacrumb">
                    <div className="breadcrumb-wrapper">
                      <ul className="breadcrumb-links">
                        <li>
                          {isAllowedTo('update', 'space', permissions) ? (
                            <a
                              onClick={() => {
                                transNavigation(
                                  startTransition,
                                  navigate,
                                  SPACE_LIST
                                );
                                dispatch(setLeavingUrl(SPACE_LIST));
                              }}
                            >
                              Spaces
                            </a>
                          ) : (
                            'Spaces'
                          )}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              transNavigation(
                                startTransition,
                                navigate,
                                `/company/spaces/${currentSpaceSel?.id}/overview`
                              );
                              dispatch(
                                setLeavingUrl(
                                  `/company/spaces/${currentSpaceSel?.id}/overview`
                                )
                              );
                            }}
                          >
                            {currentSpaceSel?.name}
                          </a>
                        </li>
                        {isFromPlayground && (
                          <li>
                            <a
                              onClick={() => {
                                navigate(
                                  `/company/spaces/${currentSpaceSel?.id}/playground/${isFromPlayground?.bcId}`
                                );
                                dispatch(
                                  setLeavingUrl(
                                    `/company/spaces/${currentSpaceSel?.id}/playground/${isFromPlayground?.bcId}`
                                  )
                                );
                              }}
                            >
                              Playground
                            </a>
                          </li>
                        )}
                        <li>Studio</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <RightPageHeader />
            </div>
          </div>
        </div>
      )}

      {!frame && (
        <section
          className="natasha-welcome has-header playground-welcome-page"
          aria-label="playground-welcome-page"
        >
          <div className="inner-content">
            <span
              className="be-close p-2 m-5 float-end close-icon"
              onClick={() => navigate(-1)}
            ></span>

            <div className={`welcome-content`}>
              <div className="container xs">
                <div className="flex-wrapper">
                  <em className="welcome-logo">
                    <img src={images.natashaLogo} alt="" />
                  </em>
                  <div className="option-selection">
                    <p className="title">
                      Welcome to the ideaboard! <br />
                      How do you want to work on your idea?
                    </p>
                    <div className="link-button-wrap">
                      <Link to="" className="link-button" onClick={getWbUrl}>
                        <em className="support-avatar">
                          <img src={images.createWhiteboard} alt="" />
                        </em>
                        <div className="title">
                          Create <br />
                          whiteboard
                        </div>
                      </Link>
                      <Link
                        to=""
                        className="link-button"
                        onClick={() => setCallMyTeam(true)}
                      >
                        <em className="support-avatar">
                          <img src={images.callTeam} alt="" />
                        </em>
                        <div className="title">
                          Call my <br />
                          team
                        </div>
                      </Link>
                      <Link
                        to=""
                        className={`link-button`}
                        data-testid="open-iframe"
                        onClick={() => {
                          setIframeLoading(true);
                          setFrame(true);
                        }}
                      >
                        <em className="support-avatar">
                          <img src={images.openStudio} alt="" />
                        </em>
                        <div className="title">
                          Open <br />
                          studio
                        </div>
                      </Link>
                      <Tooltip
                        arrow
                        placement="bottom"
                        title="Coming soon"
                        classes={{ popper: 'info-tooltip' }}
                      >
                        <Link to="" className="link-button static">
                          <em className="support-avatar icon be-upload-document"></em>
                          <div className="title">
                            Upload <br />
                            document
                          </div>
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {callMyTeam && (
        <AddMemberToBmeetDialog {...{ callMyTeam, callMyTeamDialogClose }} />
      )}

      {iframeLoading && (
        <div className="B-loading-wrapper">
          <img
            src={(images as any).buAnim}
            alt="B"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              height: '80px',
              width: '80px',
              transform: 'translate(-50%, -50%)',
              zIndex: '9999',
            }}
          />
        </div>
      )}

      {frame && <PureIframe handleIframLoad={handleIframLoad} />}

      {!!showDialog && !!frame && (
        <ExitConfirmDialog
          {...{
            condition: !!showDialog && !!frame,
            discardAndNavigate,
            setShowDialog,
          }}
        />
      )}
    </>
  );
}

type Props = {
  handleIframLoad: any;
};

const PureIframe = memo(function PureIframe({ handleIframLoad }: Props) {
  return (
    <iframe
      aria-label="iframe-label"
      title="studio application"
      onLoad={handleIframLoad}
      src={`${process.env.REACT_APP_STUDIO_URL}/home?is_source_enterprise_surface=true`}
      className="studio-iframe studio-iframe-header"
      allow="camera *; microphone *; display-capture *; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
});
